import React from 'react'
import Layout from '@compositions/Layout'

const breadcrumbs = [{ key: 'faqs', text: 'FAQs', url: '/faqs' }]

const FaqsPage = () => (
  <Layout breadcrumbs={breadcrumbs}>
    <h1>FAQs Page</h1>
    <p>Lorem ipsum dolor sit amet consectetum...</p>
  </Layout>
)

export default FaqsPage
